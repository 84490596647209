<template>
  <div class="filter-box">
    <!-- 表单 -->
    <div class="filter-form">
      <el-form :inline="true" labelWidth="85px">
        <el-form-item label="手机号：" label-width="70px">
          <ml-input
            placeholder="请输入手机号"
            v-model="formParams.phone"
            clearable
          ></ml-input>
        </el-form-item>

        <el-form-item label="姓名：" label-width="70px">
          <ml-input
            placeholder="请输入姓名"
            v-model="formParams.name"
            clearable
          ></ml-input>
        </el-form-item>

        <el-form-item label="状态：" label-width="70px">
          <ml-select
            :options="enableDisableTypes"
            v-model="formParams.status"
            placeholder="请选择状态"
            clearable
          >
          </ml-select>
        </el-form-item>

        <el-form-item>
          <ml-button type="" @handleClick="submit">搜索</ml-button>
          <ml-button type="primary" @handleClick="reset"> 重置 </ml-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import { enableDisableOpts } from "@/utils/options";

const emits = defineEmits(["submit", "reset"]);

/* 用户状态选项信息 */
const enableDisableTypes = [
  {
    label: "全部",
    value: "",
  },
  ...enableDisableOpts(),
];

// 默认数据
const defaultParams = () => ({
  name: "",
  phone: "",
  status: "",
});
const formParams = reactive(defaultParams());

const submit = () => {
  emits("submit", formParams);
};

const reset = () => {
  const defaultData = defaultParams();
  for (let i in defaultData) {
    formParams[i] = defaultData[i];
  }
  emits("reset", defaultData);
};

/* 设置筛选参数并查询 */
const setFilterParams = (params) => {
  Object.assign(formParams, params);
  setTimeout(() => {
    submit();
  });
};

// 获取筛选参数
const getFilterParams = () => formParams;

defineExpose({
  setFilterParams,
  getFilterParams,
  submit,
});
</script>

<style lang="scss" scoped>
.filter-form {
  user-select: none;
}
</style>
