<template>
  <div class="main-manage-container">
    <panel-manage panelTitle="后台用户管理">
      <div class="up-tool-box2">
        <div class="tool-btn">
          <auth-button
            menuCode="normal_add"
            @handleClick="jumpAction({}, 'add')"
            >添加新用户</auth-button
          >
          <auth-button
            type="success"
            menuCode="enableDisable"
            @handleClick="handleEnable"
            :disabled="isDisabledBatchToolBtn"
            >启用</auth-button
          >
          <auth-button
            type="danger"
            menuCode="enableDisable"
            @handleClick="handleDisable"
            :disabled="isDisabledBatchToolBtn"
            >禁用</auth-button
          >
          <auth-button
            type="danger"
            menuCode="normal_delete"
            :disabled="isDisabledBatchToolBtn"
            @handleClick="onBatchDel()"
            >批量删除</auth-button
          >
        </div>
      </div>

      <div class="table-action-container">
        <filter-box
          @reset="resetCall"
          @submit="filterCall"
          ref="filterBoxRef"
        ></filter-box>

        <table-box ref="tableBoxRef"></table-box>
      </div>
    </panel-manage>
  </div>
</template>
success
<script setup>
import filterBox from "./components/filter.vue";
import tableBox from "./components/table.vue";

import {
  onMounted,
  ref,
  getCurrentInstance,
  computed,
  reactive,
  nextTick,
} from "vue";

// 筛选表格组件
// ref
const filterBoxRef = ref();

/* 表格组件操作 */
const tableBoxRef = ref();
const filterCall = (params) => {
  tableBoxRef.value.fetchFilterData(params);
  openTableLoading();
};
const resetCall = (params) => {
  tableBoxRef.value.resetParams(params);
  openTableLoading();
};

// 表格加载
const openTableLoading = () => {
  tableBoxRef.value.openTableLoading();
};

/* 表格操作 */
// 启用
const handleEnable = () => {
  tableBoxRef.value.openEnableAndDisableDialog(1);
};
// 禁用
const handleDisable = () => {
  tableBoxRef.value.openEnableAndDisableDialog(0);
};

// 跳转设置页面
const jumpAction = (data = {}, actionType) => {
  tableBoxRef.value.openBucketDialog((data = {}), actionType);
};

// 禁用批量按钮
const isDisabledBatchToolBtn = computed(
  () => !!tableBoxRef.value && tableBoxRef.value.isDisabledBatchToolBtn
);

// 批量移除
const onBatchDel = () => {
  tableBoxRef.value.openDeleteDialog();
};

// 初始
const init = () => {
  filterBoxRef.value.submit();
};

onMounted(() => {
  init();
});
</script>

<style lang="scss" scoped>
@import "@/assets/css/main_page.scss";
</style>
