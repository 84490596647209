<template>
  <panel-main ref="panelMainRef">
    <!-- 表格区域 -->
    <div class="table-content">
      <!-- 表格 -->
      <ml-table
        ref="tableRef"
        :tableData="tableData"
        :templateData="templateData"
        :pageParams="pageParams"
        tableHeight="100%"
        :useJumpClick="false"
        @onSelectionChange="onSelectionChange"
        :templateAuths="{
          btnActive: [
            'normal_reset_password',
            'normal_update',
            'normal_delete',
            'normal_reset_add',
          ],
          status: ['enableDisable'],
        }"
      >
        <template #status="{ row }">
          <div>
            <auth-switch
              menuCode="enableDisable"
              :disabled="row.isAdmin || row.id == currLoginUserInfo.id"
              :beforeChange="userStatusSwitch(row)"
              v-model="row.status"
            ></auth-switch>
          </div>
        </template>

        <template #name="{ row }">
          <introduce-info
            :avator="row.headPortrait"
            :firstText="row.name"
            errorEmptyType="default"
            isUsePreviewUrl
            mode="circle"
          >
          </introduce-info>
        </template>

        <template #btnActive="{ row }">
          <auth-button
            menuCode="normal_update"
            type="warning"
            size="small"
            :disabled="(systemUserManage || systemUserNormal) && row.isAdmin"
            @handleClick="openBucketDialog(row, 'edit')"
            >编辑</auth-button
          >
          <auth-button
            type="primary"
            size="small"
            menuCode="normal_reset_password"
            :disabled="(systemUserManage || systemUserNormal) && row.isAdmin"
            @handleClick="openResetDialog(row)"
            >重置密码</auth-button
          >

          <auth-button
            type="danger"
            size="small"
            menuCode="normal_delete"
            :disabled="
              ((systemUserManage || systemUserNormal) && row.isAdmin) ||
              row.id == currLoginUserInfo.id
            "
            @handleClick="openDeleteDialog(row)"
            >删除</auth-button
          >
        </template>
      </ml-table>
    </div>
  </panel-main>

  <!-- 用户 弹窗 -->
  <tool-dialog
    ref="userDialogRef"
    :title="userDialogConfig.dialogTitle"
    width="700px"
    marginTop="5vh"
    @confirm="userSubmitForm"
  >
    <ml-form
      ref="bucketFormRef"
      label-position="top"
      className="hide-scrollbar file-type-form"
      :model="userFormData"
      :rules="bucketFormRules"
    >
      <el-form-item label="头像：" prop="headPortrait">
        <image-multifunctional
          v-model="imageFile"
          :maxCount="1"
          sizeMode="rect"
          uploadAreaWidth="140px"
          uploadAreaHeight="140px"
          folder="HEAD_PORTRAIT"
        ></image-multifunctional>
      </el-form-item>
      <el-form-item label="手机号：" prop="phone">
        <ml-input
          placeholder="请输入手机号"
          v-model="userFormData.phone"
          maxlength="11"
          :disabled="userDialogConfig.isDisabledSystemManage"
        ></ml-input>
      </el-form-item>
      <el-form-item label="姓名：" prop="name" class="storage-capacity-item">
        <ml-input
          placeholder="请输入姓名"
          v-model="userFormData.name"
        ></ml-input>
      </el-form-item>

      <el-form-item label="登录密码：" prop="password">
        <ml-input
          :placeholder="`${isEditForm ? '********' : '请输入登录密码'}`"
          type="password"
          v-model="userFormData.password"
        ></ml-input>
      </el-form-item>
      <el-form-item label="确认密码：" prop="againPassword">
        <ml-input
          :placeholder="`${isEditForm ? '********' : '请输入确认密码'}`"
          type="password"
          className="password-aggin"
          v-model="userFormData.againPassword"
        ></ml-input>
      </el-form-item>
      <el-form-item label="角色选择：" prop="roleIds">
        <lazy-select
          class="inline-block"
          v-model="roleValues"
          ref="ruleDialogRef"
          placeholder="请选择角色"
          popperClass="select2-popper2"
          storeDispatchName="fetchGetRolePageChooser"
          :multiple="true"
          :filterable="false"
          :disabled="userDialogConfig.isDisabledSystemManage"
          :selectProps="{
            labelKey: 'name',
            valueKey: 'id',
          }"
        >
        </lazy-select>
      </el-form-item>
      <el-form-item
        label="状态："
        prop="status"
        v-if="
          !userDialogConfig.isDisabledSystemManage &&
          userFormData.id != currLoginUserInfo.id
        "
      >
        <el-radio-group v-model="userFormData.status">
          <el-radio
            :label="item.value"
            size="large"
            v-for="(item, index) in storeStatusType"
            :key="'item' + index"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
    </ml-form>
  </tool-dialog>

  <!-- 重置密码 询问弹出窗 -->
  <ask-confirm
    type="warning"
    title="重置密码"
    ref="askDialogRef"
    @confirm="resetConfirm"
    :askTips="['您确定要重置此用户的密码吗？', '初始密码为：']"
  >
    <div>
      <ml-input
        class="inline-block"
        type="text"
        v-model="defaultPasswordVal"
      ></ml-input>
    </div>
  </ask-confirm>
</template>

<script setup>
import {
  ref,
  onMounted,
  reactive,
  getCurrentInstance,
  computed,
  nextTick,
  watch,
} from "vue";
import { useTableParamsModule, usePageModule } from "@/utils/mixin";
import { enableDisableOpts } from "@/utils/options";
import { testPwd } from "@/utils/regExp";
import { ruleNormalPhone } from "@/utils/formRules";

const props = defineProps({
  tabValue: {
    type: [String, Number],
    default: "",
  },
});

const {
  proxy,
  systemUserManage,
  systemUserNormal,
  currLoginUserInfo,
  mainStore,
} = usePageModule();

/* 选型数据 */
// 用户状态
const storeStatusType = enableDisableOpts();

// 面板
// ref
const panelMainRef = ref();

// 下拉选择器
//ref
const ruleDialogRef = ref();

// 表格配置
const {
  resetParams,
  tableData,
  pageParams,
  fetchFilterData,
  getTableList,
  openTableLoading,
  tableRef,
  filterParams,
} = useTableParamsModule({
  fetchTableName: "fetchGetSystemUserPageList",
  setCallBackRes(res) {
    const { total, result } = res;

    const data = result.map((d) => {
      const isAdmin = d.phone === "admin";
      return {
        ...d,
        isAdmin,
      };
    });

    return {
      total,
      result: data,
    };
  },
});

// 表格模板数据
const templateData = [
  {
    type: "selection",
    label: "复选",
    width: "50",
    align: "center",
  },
  {
    type: "index",
    label: "序号",
    width: "120",
    align: "left",
  },
  {
    prop: "phone",
    label: "手机号",
    minWidth: "170",
    align: "left",
  },
  {
    prop: "name",
    label: "姓名",
    minWidth: "170",
    showTemplate: true,
    align: "left",
  },
  {
    prop: "roleNames",
    label: "角色",
    minWidth: "180",
    align: "left",
  },
  {
    prop: "createTime",
    label: "创建时间",
    minWidth: "170",
    align: "left",
  },
  {
    prop: "loginTime",
    label: "最后登录时间",
    minWidth: "170",
    align: "left",
  },
  {
    prop: "status",
    label: "状态",
    minWidth: "150",
    showTemplate: true,
    align: "left",
  },

  {
    prop: "btnActive",
    label: "操作",
    showTemplate: true,
    align: "left",
    fixed: "right",
    minWidth: "250",
  },
];

// *********************************************************************************************************
// 表格数据操作  START
// *********************************************************************************************************

/* 删除用户 */

// 勾选表格的数据
const checkTableDatas = ref([]);
const checkTableDataIds = computed(() =>
  checkTableDatas.value.map((d) => d.id)
);

// 是否禁用批量按钮
const isDisabledBatchToolBtn = computed(() => !checkTableDatas.value.length);

// 监听表格勾选
const onSelectionChange = (res) => {
  checkTableDatas.value = res.filter((d) => {
    const isPass = !d.isAdmin && d.id != currLoginUserInfo.value.id;
    return isPass;
  });
};

// 打开弹窗
const openDeleteDialog = (item) => {
  // 提示
  const ask1 = item ? "该" : "所选";

  panelMainRef.value.askDialog({
    type: "danger",
    title: "删除用户",
    askTips: [`您确定要删除${ask1}用户吗？`],
    confirm(close) {
      let result = "";
      if (item) {
        const { id } = item;
        result = id;
      } else {
        // 批量
        result = checkTableDataIds.value.join(",");
      }
      dispatchDelete(result, close);
    },
  });
};
// 调用删除
const dispatchDelete = async (ids, close) => {
  try {
    await proxy.$storeDispatch("fetchBatchRemoveSystemUser", { ids });
    proxy.$successMsg("删除成功");
    // 重置表格勾选数据
    checkTableDatas.value = [];
    getTableList();
    close();
  } catch (error) {
    console.log(error);
    close();
  }
};

/* 启用禁用 */
// 打开弹窗
const openEnableAndDisableDialog = (status) => {
  // 提示
  const statusTexts = ["禁用", "启用"];
  const ask1 = statusTexts[status];

  panelMainRef.value.askDialog({
    type: "danger",
    title: ask1 + "用户",
    askTips: [`您确定要${ask1}用户吗？`],
    async confirm(close) {
      // 批量
      let ids = checkTableDataIds.value.join(",");

      try {
        await modifySystemUserStatus({
          ids,
          status,
        });
        close();
      } catch (error) {
        close();
      }
    },
  });
};
// 启用禁用开关
const userStatusSwitch = (item) => {
  return () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { id } = item;
        const status = item.status ? 0 : 1;
        const param = {
          ids: id,
          status,
        };
        const result = await modifySystemUserStatus(param);
        resolve(result);
      } catch (error) {
        console.log(error);
        reject(false);
      }
    });
  };
};

// 修改用户状态
const modifySystemUserStatus = async (params) => {
  try {
    const res = await proxy.$storeDispatch(
      "fetchModifySystemUserStatus",
      params
    );
    getTableList();
    return res;
  } catch (error) {
    return false;
  }
};

/* 用户弹窗操作 */
// ref
const userDialogRef = ref();
const bucketFormRef = ref();

// 表单信息
const userFormDataDefault = () => ({
  id: "",
  headPortrait: "",
  phone: "",
  name: "",
  password: "",
  againPassword: "",
  roleIds: [],
  status: 1,
});
const userFormData = reactive(userFormDataDefault());

const bucketFormRules = computed(() => {
  // 是否是编辑
  const { isEdit } = userDialogConfig.value;
  const rules = {
    phone: ruleNormalPhone(),
    name: [
      {
        required: true,
        trigger: "change",
        message: "请输入姓名",
      },
    ],
    password: [],
    againPassword: [],
    roleIds: [
      {
        required: true,
        trigger: "change",
        message: "请选择角色",
      },
    ],
  };

  const pwdRules = [
    {
      validator: testNormalPwd,
    },
    {
      validator: testPwdIdentical,
    },
  ];
  rules.password = pwdRules;
  rules.againPassword = pwdRules;
  if (!isEdit) {
    rules.password.unshift({
      required: true,
      trigger: "change",
      message: "请先输入登陆密码",
    });
    rules.againPassword.unshift({
      message: "请先确认密码",
      trigger: "change",
      required: true,
    });
    return rules;
  } else {
    rules.password = pwdRules;
    rules.againPassword = pwdRules;
    return rules;
  }
});

/* 修改密码 */
// 校验常规密码
const testNormalPwd = (rule, value, callback) => {
  const len = (value || "").toString().length;
  if ((value && len < 6) || len > 20) {
    callback(new Error("请输入6-20位的密码"));
  } else {
    callback();
  }
};
// 验证密码是否一致
const testPwdIdentical = (rule, value, callback) => {
  if (userFormData.password || userFormData.againPassword) {
    if (userFormData.password != userFormData.againPassword) {
      callback(new Error("两次密码不一致"));
    } else {
      callback();
    }
  } else {
    callback();
  }
};

// 角色数据
const roleValues = ref([]);

watch(
  () => roleValues.value,
  (nVal) => {
    userFormData.roleIds = nVal;
  }
);

// 选中的数据
const chooseItem = reactive({});

// 弹窗配置
const userDialogConfig = computed(() => {
  const { actionType, isAdmin } = chooseItem;

  const isEdit = actionType == "edit";
  const actionTypeText = isEdit ? "修改" : "添加";
  const dialogTitle = `${actionTypeText}用户`;

  // 表单操作接口
  const formFetchName = isEdit ? "fetchModifySystemUser" : "fetchAddSystemUser";

  // 超级管理员禁用项
  // 管理员角色 禁止编辑账号、权限
  const isDisabledSystemManage = isAdmin && isEdit;

  return {
    dialogTitle,
    actionTypeText,
    formFetchName,
    isEdit,
    isDisabledSystemManage,
  };
});

// 打开弹窗
const openBucketDialog = (data = {}, actionType) => {
  // 赋值
  Object.assign(chooseItem, { ...data, actionType });

  userDialogRef.value.openDialog();

  nextTick(async () => {
    roleValues.value = [];
    // 获取角色列表数据
    await ruleDialogRef.value.getSelectList("init");

    // 编辑
    if (userDialogConfig.value.isEdit) {
      const result = await getSystemUserDetailsReporting(data.id);
      // 赋值
      if (result.headPortrait) {
        imageFile.value = [{ relUrl: result.headPortrait }];
      }
      proxy.$updateParams(userFormData, result);
      roleValues.value = result.roleIds;
    } else {
      proxy.$updateParams(userFormData, userFormDataDefault());
      imageFile.value = [];
      setTimeout(() => {
        bucketFormRef.value.clearValidate();
      });
    }
  });
};

// 表单提交
const userSubmitForm = async (closeLoading) => {
  const testRes = await proxy.$testForm(closeLoading, bucketFormRef.value);
  if (!testRes) {
    return;
  }
  const { actionTypeText, formFetchName, isEdit } = userDialogConfig.value;

  const data = [];
  if (userFormData.roleIds) {
    for (let i = 0; i < userFormData.roleIds.length; i++) {
      const val = userFormData.roleIds[i];
      data.push(val);
    }
  }

  const ids = data.map((item) => `roleIds=${item}`).join("&");
  // console.log(ids);

  let result = proxy.$removeDecontextAttr(userFormData);

  delete result["roleIds"];
  delete result["againPassword"];
  if (isEdit) {
    // 如果是没有修改密码 清除password字段，不传给接口
    if (proxy.$isEmpty(result.password)) {
      delete result["password"];
    }
  }

  // console.log(result);
  try {
    await proxy.$storeDispatch(formFetchName, {
      params: result,
      query: ids,
    });
    proxy.$successMsg(actionTypeText + "成功");
    closeLoading();
    userDialogRef.value.closeDialog();
    getTableList();

    // 编辑
    if (isEdit) {
      console.log(result.id === currLoginUserInfo.value.id);
      // 编辑的是当前用户
      if (result.id === currLoginUserInfo.value.id) {
        const { name, headPortrait } = result;
        proxy.$updateParams(
          mainStore.userInfo,
          {
            name,
            headPortrait,
          },
          {
            ignoreNull: true,
          }
        );

        // console.log(mainStore.userInfo);
      }
    }
  } catch (error) {
    console.log(error);
    closeLoading();
  }
};

// 表格选中数据
const changeTableItem = ref({});

/* 头像数据 */
const imageFile = ref([]);
watch(
  () => imageFile.value,
  (nVal) => {
    // console.log(nVal);
    userFormData.headPortrait = nVal.map((d) => d.relUrl).join(",");
  },
  {
    deep: true,
  }
);

// 是否是编辑
const isEditForm = computed(() => {
  const { isEdit } = userDialogConfig.value;

  return isEdit;
});

/* 重置密码 */
// 默认密码 数据
const initPassword = () => "grp123456";
const defaultPasswordVal = ref(initPassword());
// ref
const askDialogRef = ref();
// 打开弹窗
const openResetDialog = (item) => {
  changeTableItem.value = item;

  defaultPasswordVal.value = initPassword();
  askDialogRef.value.openDialog();
};
// 重置确认
const resetConfirm = (close) => {
  const { id } = changeTableItem.value;

  const value = defaultPasswordVal.value;

  if (!value) {
    proxy.$warningMsg("请填写初始密码");
    close();
    return;
  } else if (!testPwd(value)) {
    proxy.$warningMsg("请输入6-20位的密码");
    close();
    return;
  }
  dispatchResetPwd(id, close);
};
// 调用重置
const dispatchResetPwd = async (userId, close) => {
  try {
    const password = defaultPasswordVal.value;
    await proxy.$storeDispatch("fetchResetSystemUserPassword", {
      userId,
      password,
    });
    proxy.$successMsg("重置成功");
    getTableList();
    close();
    askDialogRef.value.closeDialog();
  } catch (error) {
    console.log(error);
    close();
  }
};

// *********************************************************************************************************
// 表格数据操作  END
// *********************************************************************************************************

// *********************************************************************************************************
// 查询 START
// *********************************************************************************************************

// 查询用户详情
const getSystemUserDetailsReporting = async (id) => {
  try {
    const res = await proxy.$storeDispatch(
      "fetchGetSystemUserDetailsReporting",
      {
        id,
      }
    );
    const result = res.result;
    return result;
  } catch (error) {
    return {};
  }
};

// *********************************************************************************************************
// 查询 END
// *********************************************************************************************************

// 模拟数据
// const data = {
//   result: [
//     {
//       phone: "18595852485",
//       status: true,
//     },
//   ],
//   total: 0,
// };

defineExpose({
  resetParams,
  fetchFilterData,
  getTableList,
  openTableLoading,
  openBucketDialog,
  isDisabledBatchToolBtn,
  openDeleteDialog,
  openEnableAndDisableDialog,
});
</script>

<style lang="scss" scoped>
.table-content {
  height: 100%;
}

// .password-aggin {
//   ::v-deep(.el-input__inner::placeholder) {
//     font-size: 18px;
//   }
// }
</style>

<style lang="scss">
.file-type-form {
  .storage-capacity-item {
    margin-bottom: 27px;
  }
}
</style>
