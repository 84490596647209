// 启用/禁用状态
export const enableDisableOpts = () => {
  return [
    {
      label: "启用",
      value: 1,
    },
    {
      label: "禁用",
      value: 0,
    },
  ];
};

// 是/否
export const whetherOpts = () => {
  return [
    {
      label: "是",
      value: 1,
    },
    {
      label: "否",
      value: 0,
    },
  ];
};

// 男女
export const genderOpts = () => [
  {
    label: "男",
    value: 1,
  },
  {
    label: "女",
    value: 2,
  },
];

// 版本类型
export const versionOpts = () => [
  {
    label: "强制升级",
    value: 1,
  },
  {
    label: "普通",
    value: 0,
  },
];

// 设备示意图类型
export const deviceSketchMapOpts = () => [
  {
    label: "选择其他型号图片",
    value: 3,
  },
  {
    label: "选择新图片上传",
    value: 2,
  },
  {
    label: "使用默认图片",
    value: 1,
  },
  {
    label: "暂无图片",
    value: 0,
  },
];
